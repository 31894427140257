import { ITicketType } from "../types/events";
import request from "../utils/request";

export const addEventTicketType = (id: number, details: ITicketType) => {
  return request({
    url: `/events/${id}/ticketType`,
    method: "post",
    data: details,
  });
};

export const removeEventTicketType = (id: number) => {
  return request({
    url: `/ticketTypes/${id}`,
    method: "delete",
  });
};

export const activateTicket = (uuid: string) => {
  return request({
    url: `/tickets/${uuid}`,
    method: "post",
  });
};

export const getEventTickets = (eventId: number, params = {}) => {
  return request({
    url: `/events/${eventId}/tickets`,
    method: "get",
    params,
    headers: {
      Accept: "application/json",
    },
  });
};

export const downloadEventTickets = (eventId: number, params = {}) => {
  return request({
    url: `/events/${eventId}/tickets/download`,
    method: "get",
    params,
    headers: {
      Accept: "text/csv; charset=utf-8",
    },
  });
};

export const downloadTicket = (uuid: string) => {
  return request({
    url: `/tickets/download/${uuid}`,
    method: "get",
    responseType: "arraybuffer",
  });
};

export const getTicket = (uuid: string) => {
  return request({
    url: `/tickets/${uuid}`,
    method: "get",
    headers: {
      Accept: "application/json",
    },
  });
};

export const updateTicket = (id: number, ticketData: ITicketType) => {
  return request({
    url: `/tickets/${id}`,
    method: "put",
    data: ticketData,
  });
};

export const postTicket = (ticketData: ITicketType) => {
  return request({
    url: `/tickets`,
    method: "post",
    data: ticketData,
  });
};

export const updateEventTicketType = (
  eventId: number,
  ticketTypeId: number,
  details: ITicketType
) => {
  return request({
    url: `/events/${eventId}/ticketType/${ticketTypeId}`,
    method: "put",
    data: details,
  });
};
