import axios, { AxiosError } from "axios";
import { store } from "../store";
import history from "../utils/history";
import { logoutUser } from "../features/user/authSlice";
import { ErrorCodes } from "../constants/errorCodes";
import { StorageItems } from "../constants/storageItems";
import Cookies from "js-cookie";

interface IValidationError {
  field: string;
  rejectedValue: any;
  message: string;
}

export interface ICustomError {
  errorCode: string;
  subErrors?: IValidationError[];
  errors: IValidationError[];
  message: string;
  status: string;
  timestamp: Date;
}

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // url = base url + request url
  timeout: 120000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    const token = Cookies.get(StorageItems.TOKEN);
    // const {errorState} = store.getState()
    //
    // if (errorState.badRequest || errorState.serverError) {
    //   console.log('handle error and redirect to 404 pge')
    // }

    config.headers["Access-Control-Allow-Origin"] = "*";

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<ICustomError>) => {
    if (
      error?.response?.status === 401 &&
      error?.response.data.errorCode !== ErrorCodes.BAD_CREDENTIALS
    ) {
      store().dispatch(logoutUser()); // TODO Check why it is not working
      history.push("/authentication/sign-in");
      location.reload();
    }
    return Promise.reject(error);
  }
);

export default service;
