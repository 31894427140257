/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, ReactNode, forwardRef } from "react";

// @mui material components
import { ButtonProps, CircularProgress } from "@mui/material";

// Custom styles for MDButton
import MDButtonRoot from "uikit/MDButton/MDButtonRoot";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import styled from "@emotion/styled";

// Declaring props types for MDButton
export interface Props extends Omit<ButtonProps, "color" | "variant"> {
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "default";
  variant?: "text" | "contained" | "outlined" | "gradient";
  size?: "small" | "medium" | "large";
  circular?: boolean;
  iconOnly?: boolean;
  loading?: boolean;
  children?: ReactNode;
  [key: string]: any;
  disabled?: boolean;
}

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MDButton: FC<Props> = forwardRef(
  ({ color, variant, size, circular, iconOnly, loading, children, disabled, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
        disabled={disabled || loading}
      >
        {
          <ButtonWrapper>
            {loading && (
              <CircularProgress
                size={20}
                sx={{ position: "absolute", margin: "auto", left: 0, right: 0 }}
              />
            )}
            {children}
          </ButtonWrapper>
        }
      </MDButtonRoot>
    );
  }
);

// Declaring default props for MDButton
MDButton.defaultProps = {
  color: "white",
  variant: "contained",
  size: "medium",
  circular: false,
  iconOnly: false,
};

export default MDButton;
